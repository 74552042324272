import React from 'react'
import { Link, graphql } from 'gatsby'
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../lib/helpers'
import GraphQLErrorList from '../components/graphql-error-list'
import tw, { theme, css } from 'twin.macro'
import Tilt from 'react-parallax-tilt'
import SEO from '../components/seo'
import Rating from '../components/rating'
import RatingHollow from '../components/rating-hollow'
import Img from 'gatsby-image'
import SanityImage from 'gatsby-plugin-sanity-image'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import Layout from '../containers/layout'
import { Helmet } from 'react-helmet'

export const query = graphql`
  query LearnListTemplateQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    learnings: allSanityLearn(
      sort: { fields: [_updatedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        next {
          id
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
        }
        previous {
          id
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
        }
        node {
          id
          publishedAt
          provider {
            authorType
            authorName
            providerType
            providerName
          }
          courseDetails {
            startDate
            endDate
            courseMode
            courseWorkload
          }
          category
          offers {
            price
            priceCurrency
          }
          rating
          sourceLink {
            url
            cta
          }
          learnings {
            mainImage {
              ...ImageWithPreview
              caption
              alt
            }
            learnedAt
            title
            rating
            slug {
              current
            }
            _rawLearnContent(resolveReferences: { maxDepth: 5 })
          }
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          commitment
          status
          textColor {
            hex
          }
          bgColor {
            hex
          }
          slug {
            current
          }
          _updatedAt
          categories {
            _id
            title
            slug {
              current
            }
          }
          mainImage {
            ...ImageWithPreview
            caption
            alt
          }
        }
      }
    }
  }
`

const LearnListTemplate = (props) => {
  const { data, errors, pageContext } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const learningNodes = data && data.learnings && mapEdgesToNodes(data.learnings)
  .filter(filterOutDocsWithoutSlugs)
  .filter(filterOutDocsPublishedInTheFuture)

  const site = data.site
  const siteTitle = site.siteMetadata.title
  const domain = site.siteMetadata.siteUrl
  
  const breakpoints = [640, 768, 1024]

  const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

  const desc =
    'These are my ongoing learning notes and reflections that I documented. While it‘s not an exhaustive list of every single things I have learned but I try to keep some kind of archives for personal references and revisions.'

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={`Personal Learnings · ${siteTitle}`}
      >
        <meta name="description" content={desc} />
        <meta name="image" content={`${domain}${data.file.childImageSharp.fluid.src}`} />

        <meta name="twitter:title" content={`Personal Learnings · ${siteTitle}`} />
        <meta name="twitter:description" content={desc} />
        <meta
          property="twitter:image"
          content={`${domain}${data.file.childImageSharp.fluid.src}`}
        />
        <meta property="twitter:card" content="summary" />

        <meta name="og:title" content={`Personal Learnings · ${siteTitle}`} />
        <meta name="og:description" content={desc} />
        <meta property="og:image" content={`${domain}${data.file.childImageSharp.fluid.src}`} />
        <meta name="og:type" content="website" />
      </Helmet>
      <section tw="w-full">
        <div
          tw="mt-5 pt-5 md:mt-16 md:pt-20 mx-6 md:mx-auto grid grid-cols-1 md:grid-cols-2 font-serif pb-10 md:pb-16 border-b"
          css={{
            width: 'min(1024px, calc(100% - 48px))',
            gridRowGap: 8,
            borderColor: `${theme`borderColor.accent`}`,
          }}
        >
          <h1 tw="text-3xl lg:text-4xl col-span-1 font-semibold ">Personal Learnings</h1>
          <p tw="col-span-1 text-softer prose prose-lg xl:prose-xl">{desc}</p>
        </div>
      </section>
      <main>
        <div
          tw="mt-10 md:mt-24 mb-10 md:mb-20 mx-auto"
          css={{
            width: 'min(1240px, calc(100% - 48px))',
          }}
        >
          {learningNodes && (
            <div tw="">
              <ResponsiveMasonry columnCountBreakPoints={{ 640: 1, 768: 2, 1024: 3 }}>
                <Masonry gutter={'1.5rem'}>
                  {learningNodes.map(learning => (
                    <>
                      <Link to={learning.slug.current} tw="w-full md:max-w-sm inline-flex">
                        <div
                          key={learning.id}
                          className="card"
                          tw="w-full md:max-w-sm rounded-xl overflow-hidden inline-block border transition ease-in-out duration-150 hover:shadow-md hover:bg-gradient-to-t via-gray-200 scale-100 hover:scale-105 transform-gpu"
                          css={{
                            borderColor: `${theme`borderColor.accent`}`,
                          }}
                        >
                          <div tw="py-6 px-4">
                            <div tw="flex flex-col">
                              {learning.status && (
                                <div tw="flex flex-row justify-between mb-8">
                                  {learning.status && (
                                    <div
                                      tw="px-2 py-1 text-xs font-medium rounded-md font-sans"
                                      css={{
                                        backgroundColor: `${learning.bgColor.hex}`,
                                        color: `${learning.textColor.hex}`,
                                        width: 'fit-content',
                                      }}
                                    >
                                      {learning.status ? `${learning.status}` : ' '}
                                    </div>
                                  )}
                                  {learning.rating > 0 && (
                                    <figure tw="flex items-center">
                                      <div tw="inline-flex space-x-1">
                                        <Rating {...learning.rating} rating={learning.rating} />
                                        <RatingHollow
                                          {...learning.rating}
                                          rating={learning.rating}
                                        />
                                      </div>
                                      <figcaption tw="font-sans sr-only">
                                        Rating: {learning.rating} out of 5
                                      </figcaption>
                                    </figure>
                                  )}
                                </div>
                              )}
                              <h3 tw="prose text-2xl font-serif">{learning.title}</h3>
                            </div>
                            <p tw="prose text-lg mt-4 text-softer font-serif">
                              {toPlainText(learning._rawExcerpt)}
                            </p>
                            {learning.categories && (
                              <ul tw="list-none flex flex-wrap mt-4 text-sm text-gray-400 whitespace-nowrap">
                                {learning.categories.map(category => (
                                  <li tw="flex-initial mr-2 lowercase inline-block">
                                    #{category.title}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                          {learning.mainImage && !learning.mainImage.asset._id.includes('png') && (
                            <SanityImage
                              {...learning.mainImage}
                              config={{ fit: 'max' }}
                              css={{
                                '&[data-lqip]': {
                                  width: '100%',
                                },
                                '&[data-loading]': {
                                  width: '100%',
                                  bottom: '-20px',
                                },
                              }}
                              tw="max-h-full object-contain m-auto border-t w-full bg-white"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                          {learning.mainImage && learning.mainImage.asset._id.includes('png') && (
                            <SanityImage
                              {...learning.mainImage}
                              config={{ fit: 'max' }}
                              css={{
                                '&[data-lqip]': {
                                  width: '100%',
                                },
                                '&[data-loading]': {
                                  width: '100%',
                                  bottom: '-20px',
                                },
                              }}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                        </div>
                      </Link>
                    </>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          )}
        </div>
      </main>
    </Layout>
  )
}

export default LearnListTemplate